/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import "./app-mobile.scss";
import "./app.scss";
//import "../utils/header.js";
import "../fonts/fontface.scss";
import "./app-desktop.scss"

const FontFaceObserver = require("fontfaceobserver");

const fontRoboto = new FontFaceObserver("roboto");
const fontRobotoCondensed= new FontFaceObserver("roboto-condensed");
const fontRobotoMono = new FontFaceObserver("roboto-mono");

let langAttr = document.documentElement.getAttribute('lang');

Promise.all([fontRoboto.load(), fontRobotoCondensed.load(), fontRobotoMono.load()]).then(
  function () {
    document.documentElement.style.setProperty(
      "--bs-font-sans-serif",
      "roboto, sans-serif"
    );
    document.documentElement.style.setProperty(
      "--bs-font-secondary",
      "roboto-roboto-mono, sans-serif"
    );
    document.documentElement.style.setProperty(
      "--bs-font-tertiary",
      "roboto-condensed, sans-serif"
    );
  }
);

let sliderBanner = document.getElementById('swiper-slider-banner');
if(sliderBanner) {
  sliderBanner.querySelector('.wp-block-group__inner-container').classList.add('swiper-wrapper');
  sliderBanner.setAttribute('data-slide-effect','default');
  sliderBanner.setAttribute('data-slide-speed','300');
  sliderBanner.setAttribute('data-slide-direction','horizontal');
  sliderBanner.setAttribute('data-slide-numberDesktop','1');
  sliderBanner.setAttribute('data-slide-numberMobile','1');
  let paginationDiv = document.createElement('div');
      paginationDiv.classList.add('swiper-pagination');
  sliderBanner.appendChild(paginationDiv);
}

import(
  /* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ "root/utils/swiper.js"
);

//Add class to header when scroll
let scrollpos = window.scrollY;
let header = document.getElementById("header");
window.addEventListener('scroll', function(){ 
    //Here you forgot to update the value
    scrollpos = window.scrollY;
    if(scrollpos > 10){
      header.classList.add("active");
    }
    else {
      header.classList.remove("active");
    }
});

//Play video
let videoDiv = document.querySelectorAll('.post-slider-item-video');
if(videoDiv) {
  videoDiv.forEach(function (video, index) {
    video.addEventListener('click', function(){
      console.log('viet');
      if (video.classList.contains('active')) {
        video.querySelector('video').pause();
        video.classList.remove('active');
      } else {
        video.querySelector('video').play();
        video.classList.add('active');
      }
    });
  });
}


 
